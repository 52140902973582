<template>
    <div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium" ref="form" :model="form"
                         :label-width="this.env.label_width">
                    <el-form-item v-if="this.Tool.is_empty(this.lesson_uuid)">
                        <el-button size="medium" type="primary" @click="template()">选择模板</el-button>
                    </el-form-item>

                    <el-form-item label="课程名称">
                        <el-input v-model="form.lesson_name"></el-input>
                    </el-form-item>
                    <el-form-item v-if="this.Tool.is_empty(this.lesson_uuid)" label="课程类型">
                        <el-radio v-model="form.lesson_type" :label="1">团课</el-radio>
                        <el-radio v-model="form.lesson_type" :label="2">训练营</el-radio>
                        <el-radio v-model="form.lesson_type" :label="3">私教</el-radio>
                    </el-form-item>
                    <el-form-item v-if="this.Tool.is_empty(this.lesson_uuid) && form.lesson_type===1" label="课程子类型">
                        <el-radio v-model="form.lesson_type2" :label="1">大团课</el-radio>
                        <el-radio v-model="form.lesson_type2" :label="2">小团课</el-radio>
                        <el-radio v-model="form.lesson_type2" :label="3">小团私</el-radio>
                    </el-form-item>
                    <el-form-item v-if="!this.Tool.is_empty(this.lesson_uuid)" label="课程类型">
                        <el-tag v-if="form.lesson_type===1" size="mini">团课</el-tag>
                        <el-tag v-if="form.lesson_type===2" size="mini">训练营</el-tag>
                        <el-tag v-if="form.lesson_type===3" size="mini">私教</el-tag>
                        <el-tag v-if="form.lesson_type2===1" size="mini" type="success">大团课</el-tag>
                        <el-tag v-if="form.lesson_type2===2" size="mini" type="success">小团课</el-tag>
                        <el-tag v-if="form.lesson_type2===3" size="mini" type="success">小团私</el-tag>
                    </el-form-item>
                    <el-form-item label="运动品类">
                        <MotionCategorySelect :motionCategoryList.sync="motion_category_two"/>
                    </el-form-item>
                    <el-form-item label="运动效果">
                        <MotioneffectSelect :motionffectList.sync="target_category_zero"/>
                    </el-form-item>
                    <el-form-item label="课程视频">
                        <Qnupload v-model="form.lesson_video" type="video"/>
                        <el-checkbox v-model="lesson_video_carousel">视频放到轮播图</el-checkbox>
                    </el-form-item>
                    <el-form-item label="课程照片">
                        <Qnupload v-model="form.lesson_photo" :isclipper="true" :fixedNumber="[4,3]" :sum="5"/>
                        <span class="form-tip">图片尺寸1024*768 或 宽:高≈4:3</span>
                    </el-form-item>
                    <el-form-item label="课程价格￥" v-if="form.lesson_type!==3">
                        <el-input-number v-model="form.price_indication" :step="0.01" :min="0"
                                         :max="999999"
                                         step-strictly
                        ></el-input-number>
                        <!--                        <el-input v-model="form.price_indication"></el-input>-->
                    </el-form-item>
                    <el-form-item label="首次体验价￥" v-if="form.lesson_type!==3">
                        <el-input-number v-model="form.price_first" :step="0.01" :min="0"
                                         :max="999999"
                                         step-strictly
                        ></el-input-number>
                        <!--                        <el-input v-model="form.price_first"></el-input>-->
                    </el-form-item>
                    <el-form-item label="课程时长(分钟)">
                        <el-input v-model="form.lesson_duration"></el-input>
                    </el-form-item>
                    <el-form-item label="课程简介">
                        <el-input v-model="form.introduce" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="2000" show-word-limit></el-input>
                        <!--                        &lt;!&ndash; 富文本编辑框 &ndash;&gt;-->
                        <!--                        <Wangeditor ref="wangeditor_introduce" v-model="form.introduce" idindex="introduce"-->
                        <!--                                    seewWidth="50%"/>-->
                    </el-form-item>
                    <el-form-item label="训练效果">
                        <el-input v-model="form.effect" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="2000" show-word-limit></el-input>
                        <!--                        <Wangeditor ref="wangeditor_effect" v-model="form.effect" idindex="effect"-->
                        <!--                                    seewWidth="50%"/>-->
                    </el-form-item>
                    <el-form-item label="适用人群">
                        <el-input v-model="form.suit" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="2000" show-word-limit></el-input>
                        <!--                        <Wangeditor ref="wangeditor_suit" v-model="form.suit" idindex="suit"-->
                        <!--                                    seewWidth="50%"/>-->
                    </el-form-item>
                    <el-form-item label="FAQ">
                        <el-input v-model="form.faq" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="2000" show-word-limit></el-input>
                        <!--                        <Wangeditor ref="wangeditor_faq" v-model="form.faq" idindex="faq"-->
                        <!--                                    seewWidth="50%"/>-->
                    </el-form-item>
                    <el-form-item label="注意事项">
                        <el-input v-model="form.attention" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="2000" show-word-limit></el-input>
                        <!--                        &lt;!&ndash; 富文本编辑框 &ndash;&gt;-->
                        <!--                        <Wangeditor ref="wangeditor_attention" v-model="form.attention" idindex="attention"-->
                        <!--                                    seewWidth="50%"/>-->
                    </el-form-item>
                    <el-form-item>
                        <el-button v-if="is_auth('lesson.lesson.issave')"
                                   size="medium" type="primary" @click="save">保存
                        </el-button>

                        <el-button v-if="!this.Tool.is_empty(this.lesson_uuid)" size="medium"
                                   @click="()=>{ this.$emit('toinfo')}">返回
                        </el-button>
                        <el-button v-else size="medium" @click="isreturn">返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-dialog v-loading="loading"
                   title="选择模板"
                   :visible.sync="template_page"
                   width="70%"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
        >
            <el-row>
                <Template v-on:template_select="template_select"/>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
// import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'
import Template from './Template'
import MotionCategorySelect from '@/components/MotionCategorySelect'
import MotioneffectSelect from '@/components/MotioneffectSelect'

export default {
    components: {
        // Wangeditor,
        Qnupload,
        Template,
        MotionCategorySelect,
        MotioneffectSelect
    },
    data() {
        return {
            select_tag: '',
            tag_list: [],
            loading: true,
            lesson_uuid: '',
            form: {
                lesson_type: 1,
                lesson_type2: 1,
                lesson_video: '',
                lesson_photo: [],
                lesson_tag: [],
                price_indication: 0,
                price_first: 0,
            },
            lesson_video_carousel: false,    // 视频是否加入轮播图
            template_page: false, // 模板显示隐藏
            motion_category_two: [],
            target_category_zero: [],
        }
    },
    // 创建
    created() {
        this.loading = false
        this.init()
        this.gettag()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 打开模板
        template() {
            this.template_page = true
        },
        // 模板选择
        template_select(data) {
            // console.log('data', data)
            this.form = data
            this.template_page = false
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let lesson_uuid = this.$route.query.lesson_uuid
            if (lesson_uuid !== undefined) {
                this.lesson_uuid = lesson_uuid
                this.getinfo()
            } else {
                this.loading = false
            }
        },
        // 获取标签
        gettag() {
            let postdata = {
                api_name: 'lesson.lesson.gettag',
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tag_list = json.data
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 添加标签
        add_tag() {
            let select_tag = this.select_tag.split("|")
            let tag = {
                tag_name: select_tag[0],
                tag_colour: select_tag[1],
            }
            if (!this.tag_exist(tag.tag_name)) {
                this.form.lesson_tag.push(tag)
            }
            this.select_tag = ''
        },
        // 判断标签是否存在
        tag_exist(tag_name) {
            if (this.form.lesson_tag.length > 0) {
                for (let v of this.form.lesson_tag) {
                    if (tag_name == v.tag_name) {
                        return true
                    }
                }
                return false
            } else {
                return false
            }
        },
        // 删除标签
        tagClose(tag) {
            this.form.lesson_tag.splice(this.form.lesson_tag.indexOf(tag), 1);
        },
        // 门店详情
        getinfo() {
            let postdata = {
                api_name: "lesson.lesson.getinfo",
                token: this.Tool.get_l_cache('token'),
                lesson_uuid: this.lesson_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form = json.data
                    // this.$refs.wangeditor_introduce.append(json.data.introduce);    // 课程简介
                    // this.$refs.wangeditor_effect.append(json.data.effect);          // 训练效果
                    // this.$refs.wangeditor_suit.append(json.data.suit);              // 适用人群
                    // this.$refs.wangeditor_faq.append(json.data.faq);                // FAQ
                    // this.$refs.wangeditor_attention.append(json.data.attention);    // 注意事项

                    this.lesson_video_carousel = json.data.lesson_video_carousel === 1 ? true : false      //视频是否加入轮播图
                    this.motion_category_two = [...json.data.lesson_motion_category]
                    this.target_category_zero = [...json.data.lesson_target_category]

                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        //保存
        save() {
            let postdata = {
                api_name: "lesson.lesson.issave",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.form);

            postdata.lesson_video_carousel = this.lesson_video_carousel ? 1 : 0   // 视频是否加入轮播图
            postdata.motion_category_two = this.motion_category_two.map(item=>item.id)
            postdata.target_category_zero = this.target_category_zero.map(item=>item.id)
            console.log('postdata', postdata)
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {

                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            // 判断是否有课程id，如果有返回详情（编辑的情况），没有返回列表（添加的情况）
                            if (this.Tool.is_empty(this.lesson_uuid)) {
                                this.isreturn();        // 返回到列表
                            } else {
                                this.$emit('toinfo')    // 返回到详情
                            }
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            // this.$router.push({path: '/lesson/lesson'})
            this.$router.go(-1)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
