<template>
  <div>
    <div class="category-select">
      <el-select
        v-model="motion_tag"
        filterable
        placeholder="请选择运动品类"
        style="width: 100%"
      >
      </el-select>
      <div class="category-select-child" @click="isMotionCategory = true"></div>
    </div>
    <div class="form-tag_colour-div" v-if="showTag">
      <el-tag
        :key="item.id"
        v-for="(item, index) in motion_category_two"
        class="form-tag_colour-tag"
        effect="dark"
        closable
        @close="motionClose(index)"
      >
        {{ item.name }}
      </el-tag>
    </div>

    <MotionCategory
      v-model="isMotionCategory"
      :motion_category_two="motion_category_two"
      :max="max"
      @selectData="onSelectCategoryData"
    />
  </div>
</template>

<script>
import MotionCategory from "@/components/MotionCategory";
export default {
  components: {
    MotionCategory,
  },
  props: {
    motionCategoryList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    max:{
      type: Number,
      default: 3
    },
    showTag:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isMotionCategory: false,
      motion_tag: "",
    };
  },
  watch: {
    "motion_category_two.length": {
      handler() {
        this.motion_tag =
          this.motion_category_two.length > 1
            ? this.motion_category_two[0].name + " ..."
            : this.motion_category_two.length === 1
            ? this.motion_category_two[0].name
            : "";
      },
      immediate: true,
    },
  },
  computed: {
    motion_category_two: {
      get() {
        return this.motionCategoryList;
      },
      set(v) {
        this.$emit("update:motionCategoryList", v);
      },
    },
  },
  methods: {
    motionClose(index) {
      this.motion_category_two.splice(index, 1);
    },
    onSelectCategoryData(data) {
      this.motion_category_two = data;
    },
  },
};
</script>

<style scoped>
.category-select {
  position: relative;
}
.category-select-child {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
