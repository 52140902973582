<template>
  <div>
    <div class="category-select">
      <el-select
        v-model="tag"
        filterable
        placeholder="请选择运动效果"
        style="width: 100%"
      >
      </el-select>
      <div class="category-select-child" @click="isShow = true"></div>
    </div>
    <div class="form-tag_colour-div">
      <el-tag
        :key="item.id"
        v-for="(item, index) in target_category_zero"
        class="form-tag_colour-tag"
        effect="dark"
        closable
        @close="onClose(index)"
      >
        {{ item.name }}
      </el-tag>
    </div>

    <Motioneffect
      v-model="isShow"
      :target_category_zero="target_category_zero"
      :max="max"
      @selectData="onSelectData"
    />
  </div>
</template>

<script>
import Motioneffect from "@/components/Motioneffect";
export default {
  components: {
    Motioneffect,
  },
  props: {
    motionffectList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    max:{
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      isShow: false,
      tag: "",
    };
  },
  watch: {
    "target_category_zero.length": {
      handler() {
        this.tag =
          this.target_category_zero.length > 1
            ? this.target_category_zero[0].name + " ..."
            : this.target_category_zero.length === 1
            ? this.target_category_zero[0].name
            : "";
      },
      immediate: true,
    },
  },
  computed: {
    target_category_zero: {
      get() {
        return this.motionffectList;
      },
      set(v) {
        this.$emit("update:motionffectList", v);
      },
    },
  },
  methods: {
    onClose(index) {
      this.target_category_zero.splice(index, 1);
    },
    onSelectData(data) {
      this.target_category_zero = data;
    },
  },
};
</script>

<style scoped>
.category-select {
  position: relative;
}
.category-select-child {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
