<template>
    <el-dialog title="运动效果" :visible.sync="isShow" width="800px" :close-on-press-escape="false"
      :close-on-click-modal="false" append-to-body>
      <div class="dialog-box">
        <el-row>
          <h3>选择详细效果</h3>
        </el-row>
        <el-row>
          <el-checkbox-group @change="handleChange()" v-model="checked" :max="max">
            <el-checkbox class="category-item" v-for="item in list" :key="item.id"
              :label="item.id">{{ item.name }}</el-checkbox>
          </el-checkbox-group>
        </el-row>
      </div>
      <el-row style="margin-top: 10px">
        <el-button size="medium" type="primary" @click="enter"
          >保存选择
        </el-button>
      </el-row>
    </el-dialog>
  </template>
  
  <script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      target_category_zero: {
        type: Array,
        default: () => {
          return []
        },
      },
      max:{
        type: Number
      },
    },
    data() {
      return {
        list: [],
        checked:[]
      };
    },
    watch: {
      value: {
        handler(v) {
          if (v) {
            this.getlist();
          }
        },
        immediate: true,
        
      },
    },
    computed: {
      isShow: {
        get() {
          return this.value;
        },
        set(v) {
          this.$emit("input", v);
        },
      },
    },
    methods: {
      // 获取列表
      getlist() {
  
        let postdata = {
          api_name: "category.targettag.getlist",
          token: this.Tool.get_l_cache("token"),
          pid: 0
        };
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.Tool.post_data("oss", postdata, (json) => {
          loading.close();
          if (json.code === 0 && Array.isArray(json.data.list)) {
            this.list = json.data.list;
            this.checked = []
            this.list.forEach((e) => {
                this.target_category_zero.forEach(el => {
                  if (el.id === e.id) {
                    this.checked.push(el.id)
                  }
                });
            })
          }
        });
      },
      handleChange() {
        this.list.forEach((e, i) => {
          this.$set(this.list, i, e)
        })
      },
      enter() {
        let allSelect = [];
        this.list.forEach((i) => {
          this.checked.forEach(element => {
              if(element=== i.id ){
                allSelect.push(i)
              }
          });
        });
        if(allSelect.length == 0){
          this.$message.warning("还没有选择任何效果!")
          return;
        }
        this.$emit("selectData", JSON.parse(JSON.stringify(allSelect)));
        this.isShow = false;
      },
      cancel() {
        this.isShow = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .el-dialog__body{
    padding: 20px;
  }
  .category-title {
    padding-left: 30px;
  }
  .el-checkbox-group{
    padding: 0 0 0 50px;
  }
  .category-item {
    margin-bottom: 8px;
    width: 90px;
  }
  .dialog-box{
    max-height: 500px;
    overflow-y: scroll;
  }
  </style>
  